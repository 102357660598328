@import "variables.scss"; @import "mixins.scss";
html,
body {
  padding: 0;
  margin: 0;
  font-family:
    CircularPro,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-size: 16px;
  font-weight: normal;
  background-color: $darkgray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  font-family: CircularPro;
  font-weight: normal;
  color: $silver;
  text-decoration: none;
  font-style: normal;
  font-size: 16px;
  letter-spacing: -1%;
  line-height: 22px;
}

.blue a {
  color: $blue;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}
a:active {
  text-decoration: none;
}

button:hover {
  cursor: pointer;
}

p {
  font-family: CircularPro;
  line-height: 165%;
}

p.padding {
  padding-bottom: $spacingS;
  a {
    color: $blue;
    font-weight: bold;
  }
}

div {
  padding: 0;
  margin: 0;
}

input {
  font-family: CircularPro;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: CircularPro;
  font-weight: normal;
  display: block;
  color: $silver;
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark,
h6.dark {
  color: $darkgray;
}

h1 {
  font-family: CircularPro;
  font-weight: bold;
  font-size: 80px;
  line-height: 110%;
  letter-spacing: -0.02em;
  margin: 32px 0;
  @include respond-between-tablet-and-desktop {
    font-size: 68px;
  }
  @include respond-tablet {
    font-size: 60px;
  }
  @include respond-mobile {
    font-size: 32px;
  }
}

h2 {
  font-family: CircularPro;
  font-weight: bold;
  font-size: 60px;
  line-height: 110%;
  margin: 16px 0;
  @include respond-tablet {
    font-size: 40px;
    line-height: 110%;
    margin: 16px 0;
  }
  @include respond-mobile {
    font-size: 32px;
    line-height: 110%;
    margin: 16px 0;
  }
}

h3 {
  font-family: CircularPro;
  font-weight: normal;
  font-size: 44px;
  line-height: 110%;
  margin: 16px 0;
  @include respond-tablet {
    font-size: 32px;
    line-height: 110%;
    margin: 16px 0;
  }
  @include respond-mobile {
    font-size: 24px;
    line-height: 110%;
    margin: 16px 0;
  }
}

h4 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 25px;
  margin: 8px 0;
}

h5 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 25px;
  margin: 8px 0;
}

h6 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 25px;
  margin: 8px 0;
}

h1 > em,
h2 > em,
h3 > em,
h4 > em,
h5 > em {
  color: $red;
  font-style: normal;
}

.darkgray > h1,
.darkgray > h2,
.darkgray > h3,
.darkgray > h4,
.darkgray > h5 {
  color: $darkgray;
}

.grid-columns-all {
  grid-column: 1 / 13;
  @include respond-tablet {
    grid-column: 1 / 7;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-two-thirds {
  grid-column: 1 / 8;
  @include respond-tablet {
    grid-column: 1 / 5;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-left {
  grid-column: 1 / 7;
  @include respond-tablet {
    grid-column: 1 / 4;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-right {
  grid-column: 7 / 13;
  @include respond-tablet {
    grid-column: 4 / 7;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-auto {
  grid-column: auto;
}

.grid-columns-left-50 {
  grid-column: 1 / 7;
  @include respond-tablet {
    grid-column: 1 / 4;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-right-50 {
  grid-column: 7 / 13;
  @include respond-tablet {
    grid-column: 4 / 7;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-left-25 {
  grid-column: 1 / 3;
  @include respond-tablet {
    grid-column: 1 / 2;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-right-75 {
  grid-column: 3 / 13;
  @include respond-tablet {
    grid-column: 2 / 7;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-left-75 {
  grid-column: 1 / 10;
  @include respond-tablet {
    grid-column: 1 / 6;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.grid-columns-right-25 {
  grid-column: 10 / 13;
  @include respond-tablet {
    grid-column: 6 / 7;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

table {
  display: block;
  white-space: nowrap;
  font-family: CircularPro;
  font-weight: normal;
  padding-bottom: 16px;
  overflow: auto;
  border: none;
  border-collapse: separate;
  border-spacing: 0px 2px;
  tr {
    height: 60px;
  }
  th,
  td {
    text-align: left;
    border-bottom: 1px solid $darkgray;
    padding-right: 96px;

    @include respond-tablet {
      padding-right: $spacingL;
      min-width: 100px;
    }
    @include respond-mobile {
      padding-right: $spacingL;
      min-width: 110px;
    }
  }
  th:nth-last-child(1) {
    padding-right: 0px;
  }
  td:nth-last-child(1) {
    padding-right: 0px;
  }
}

.overflow {
  display: contents;
  overflow: visible;
  margin-right: -200;
}

.col-1-4 {
  grid-column: 1 / 4;
  @include respond-tablet {
    grid-column: 1 / 3;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.col-2-4 {
  grid-column: 4 / 7;
  @include respond-tablet {
    grid-column: 3 / 5;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.col-3-4 {
  grid-column: 7 / 10;
  @include respond-tablet {
    grid-column: 5 / 7;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.col-4-4 {
  grid-column: 10 / 13;
  @include respond-tablet {
    grid-column: 1 / 3;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
  }
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.green a {
  color: #4cb872;
}

.darkgray a {
  color: #353841;
}

.spacer {
  height: 64px;
  @include respond-mobile {
    height: 128px;
  }
}

img {
  max-width: 100%;
  display: block;
}

ul {
  font-family: CircularPro;
  color: $darkgray;
  font-weight: normal;
  font-size: 24px;
  line-height: 45px;
  @include respond-tablet {
    font-size: 20px;
    line-height: 40px;
  }
  @include respond-mobile {
    font-size: 16px;
    line-height: 32px;
  }
  li {
    list-style-image: url("../../public/svg/red-checkmark.svg");
    margin-left: 30px;
    padding-inline-start: 30px;
    margin-bottom: 24px;
    @include respond-mobile {
      margin-bottom: 16px;
    }
  }
}

ol {
  font-family: CircularPro;
  color: $darkgray;
  font-weight: normal;
  font-size: 24px;
  line-height: 45px;
  @include respond-tablet {
    font-size: 20px;
    line-height: 40px;
  }
  @include respond-mobile {
    font-size: 16px;
    line-height: 32px;
  }
  li {
    list-style-image: url("../../public/svg/red-bullet.svg");
    margin-left: 30px;
    padding-inline-start: 30px;
    margin-bottom: 24px;
    @include respond-mobile {
      margin-bottom: 16px;
    }
  }
}

// Required by react-collapse
.ReactCollapse--collapse {
  transition: height 300ms;
}
